import {
  Typography,
  useMediaQuery,
  Button,
  Grid,
  Paper,
} from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import { Helmet } from "react-helmet"
import Charity from "../img/charity.svg"
import RiverInvert from "../img/riverreflect.svg"
import { withPrefix } from "gatsby"

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: "url(" + Charity + ")",
    minHeight: "100vh",
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
  },
  heroContainer: {
    maxWidth: "35%",
    marginLeft: "4%",
    "@media only screen and (max-width: 1060px)": {
      margin: "0 auto",
      maxWidth: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "4%",
    },
  },
  heroText: {
    fontFamily: "Nunito",
    fontSize: "2.7rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      paddingBottom: "5px",
    },
  },
  heroSub: {
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.05rem",
    },
  },
  aniLink: {
    textDecoration: "none",
  },
}))

export default function Landing() {
  const classes = useStyles()
  const isLarge = useMediaQuery("(max-width: 1060px)")
  const StyledButton = withStyles(theme => ({
    root: {
      borderRadius: "50px",
      backgroundColor: "#0072ab",
      color: "white",
      textTransform: "none",
      margin: "20px",
      fontSize: "1.7rem",
      padding: "7px 20px",
      "&:hover": {
        backgroundColor: "#177bad",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
        margin: "10px",
      },
    },
  }))(Button)
  return (
    <div>
      <Helmet>
        <link rel="stylesheet" href={withPrefix("landing.css")} />
      </Helmet>
      <div className={classes.blurBox}>
        {isLarge === false ? (
          <div style={{ height: "40vh" }} />
        ) : (
          <div style={{ height: "10vh" }} />
        )}
        <div className={classes.heroContainer} id="herocontainer">
          <Typography className={classes.heroText}>
            We are Team Avery Essex.
          </Typography>
          <Typography className={classes.heroSub}>
            We're a charitable organisation helping to empower diversity across
            Essex and beyond.
          </Typography>
          <Grid container justify="center">
            <AniLink
              to="/home"
              paintDrip
              hex="#ffffff"
              className={classes.aniLink}
            >
              <StyledButton>Get To Know Us</StyledButton>
            </AniLink>
          </Grid>
        </div>
      </div>
    </div>
  )
}
